import { Component, Input } from '@angular/core';

@Component({
  selector: 'tbc-sync-status',
  templateUrl: './tbc-sync-status.component.html',
  styleUrl: './tbc-sync-status.component.scss'
})
export class TbcSyncStatusComponent {
 @Input() sync_data: any;
 }
