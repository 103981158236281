<div class="sync-wrapper">
  <div class="sync-info">
    <strong>حالة المزامنة : </strong> <span> {{sync_data.label ? sync_data.label : '   '}} </span>
  </div>
  <div class="sync-info">
    <strong>بداية المزامنة : </strong> <span>{{sync_data.sync_started_at ? sync_data.sync_started_at : '  '}}</span>
  </div>
  <div class="sync-info">
    <strong>نهاية المزامنة : </strong> <span>{{sync_data.sync_completed_at ? sync_data.sync_completed_at : '  '}}</span>
  </div>
  @if(sync_data?.sync_failed_at) {
  <div class="sync-info">
    <strong>فشل المزامنة : </strong> <span>{{sync_data.sync_failed_at ? sync_data.sync_failed_at : ' '}}</span>
  </div>
  }
</div>
